import { ServiceHistoryUpdatableProps, ServiceMetric, ServiceType, Thing } from '@eagle/core-data-types';
import { Box, Button, Collapse, FormControlLabel, FormGroup, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { DEFAULT_TIME_ZONE } from '../../constants';
import { useSmallScreen } from '../../hooks';
import { Nullable, SetState, Undefinable } from '../../types';
import { DateErrors, DateTimeRangeError } from '../../util';
import { ConfirmDialog } from '../confirm-dialog';
import { DatePicker } from '../date-time-range-picker/date-picker';
import { useBoolFlag } from '../flags';
import { LoadingButton } from '../loading-button';
import { NextServiceHistory } from './next-service-history';
import { ServiceHistoryDialog } from './service-history-dialog';
import { ServiceHistoryExtended } from './service-history.types';
import { ServiceMetricFields } from './service-metric-fields';
import { ServiceTypeSelect } from './service-type-select';
import { useUpdateService } from './service.utils';

interface Props extends PropsWithChildren {
  thing: Thing;
  item?: ServiceHistoryExtended;
  'data-testid'?: string;
  handleClose: () => void;
  open: boolean;
  saveInProgress: boolean;
  setSaveInProgress: SetState<boolean>;
  serviceMetrics: ServiceMetric[];
  serviceTypes: ServiceType[];
  setRefreshList: SetState<Date>;
}

interface LastServiceMetrics {
  value: number;
  occurred: string;
}

export interface NextServiceData extends Omit<ServiceHistoryUpdatableProps, 'occurred'> {
  occurred: Date | null;
}

const CHARACTER_LIMIT = 200;

export const UpdateCreateServiceHistoryDialog: FC<Props> = ({
  thing,
  item,
  handleClose,
  open,
  saveInProgress,
  setSaveInProgress,
  serviceMetrics,
  serviceTypes,
  setRefreshList,
  ...props
}) => {
  const { axios } = useAuthenticated();
  const { t } = useTranslation(['common', 'admin']);
  const smallScreen = useSmallScreen();
  const thingId = thing._id;
  const isNewService = !item;
  const isPreFillEnabled = useBoolFlag('portals-servicing-module-prepopulate-latest-service-metrics-feature') && isNewService;
  const isNextServiceEnabled = useBoolFlag('portals-servicing-module-next-service-feature');
  const serviceUtils = useUpdateService(thingId, item);
  const [dateError, setDateError] = useState<Undefinable<string>>(undefined);
  const [metricsErrors, setMetricsErrors] = useState<Record<string, string>>({});
  const [valid, setValid] = useState(false);
  const [showNextService, setShowNextService] = useState<boolean>(isNextServiceEnabled || false);
  const [serviceHistoryData, setServiceHistoryData] = useState<{ currentService: ServiceHistoryUpdatableProps; nextService: NextServiceData; isNextServiceExist: boolean }>({
    currentService: {
      serviceTypeId: '',
      details: '',
      occurred: new Date(),
      serviceMetrics: {},
    },
    nextService: {
      serviceTypeId: '',
      details: '',
      occurred: null,
      serviceMetrics: {},
    },
    isNextServiceExist: false,
  });

  const serviceMetricsDisplay = useMemo(() => {
    return serviceMetrics.map((item) => item.display).join(', ');
  }, [serviceMetrics]);

  const handlePreFill = async (): Promise<void> => {
    try {
      setSaveInProgress(true);
      const { data } = await axios.get<Record<string, LastServiceMetrics>>(`/api/v1/last-service-metric/thing/${thingId}`, {
        params: {
          occurred: DateTime.fromJSDate(serviceHistoryData.currentService.occurred).set({ hour: 23, minute: 59 }).toJSDate(),
        },
      });

      const processedMetrics = Object.keys(data).reduce((acc, metricId) => {
        acc[metricId] = data[metricId].value;
        return acc;
      }, {});

      setServiceHistoryData((prev) => ({
        ...prev,
        currentService: {
          ...prev.currentService,
          serviceMetrics: processedMetrics,
        },
      }));

      const dataLength = Object.keys(data).length;

      if (dataLength > 0) {
        enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.pre-fill-readings.success.hint', { serviceMetric: serviceMetricsDisplay }), { variant: 'success' });
      } else {
        enqueueSnackbar(t('common:page.thing-detail.service-history-dialog.pre-fill-readings.info.hint', { serviceMetric: serviceMetricsDisplay }), { variant: 'info' });
      }
    } catch (error) {
      console.error('Error fetching last service metrics:', error);
    } finally {
      setSaveInProgress(false);
    }
  };

  const handleDateChange = (date: Nullable<DateTime>): void => {
    setDateError(undefined);
    if (!date) {
      setDateError(DateTimeRangeError.INVALID_DATE);
      return;
    }

    if (date.invalidReason) {
      switch (date.invalidReason) {
        case DateErrors.UNPARSABLE:
          setDateError(DateTimeRangeError.INVALID_FORMAT);
          break;
        default:
          setDateError(DateTimeRangeError.INVALID_DATE);
          break;
      }
      return;
    }

    if (date.isValid && date > DateTime.now()) {
      setDateError(DateTimeRangeError.FUTURE_DATE);
      return;
    }

    setDateError(undefined);
    setServiceHistoryData((prev) => ({
      ...prev,
      currentService: {
        ...prev.currentService,
        occurred: date ? date.toJSDate() : new Date(),
      },
    }));
  };

  const handleMetricChange = (metricId: string, value: string | number | undefined): void => {
    setServiceHistoryData((prev) => ({
      ...prev,
      currentService: {
        ...prev.currentService,
        serviceMetrics: {
          ...prev.currentService.serviceMetrics,
          [metricId]: value !== '' ? value : undefined,
        },
      },
    }));
  };

  const handleSave = async (): Promise<void> => {
    setSaveInProgress(true);

    const serviceData = {
      currentService: {
        ...serviceHistoryData.currentService,
        details: serviceHistoryData.currentService.details || null,
      },
      ...(showNextService && {
        nextService: {
          ...serviceHistoryData.nextService,
          details: serviceHistoryData.nextService.details || null,
          occurred: serviceHistoryData.nextService.occurred as Date,
        },
      }),
      isNextServiceExist: serviceHistoryData.isNextServiceExist,
    };

    if (isNewService) {
      await serviceUtils.createService(serviceData, {
        onSuccess: () => { setSaveInProgress(false); handleClose(); setRefreshList(new Date()); },
        onFailure: () => setSaveInProgress(false),
      });
    } else {
      await serviceUtils.updateService(serviceData, {
        onSuccess: () => { setSaveInProgress(false); handleClose(); setRefreshList(new Date()); },
        onFailure: () => setSaveInProgress(false),
      });
    }
  };

  const handleDelete = async (): Promise<void> => {
    setSaveInProgress(true);
    if (!isNewService) {
      await serviceUtils.deleteService({
        onSuccess: () => { setSaveInProgress(false); handleClose(); setRefreshList(new Date()); },
        onFailure: () => setSaveInProgress(false),
      });
    }
  };

  useEffect(() => {
    const isCurrentServiceValid = !Object.values(metricsErrors).some((error) => error !== '') && !dateError && Object.values(serviceHistoryData.currentService.serviceMetrics).some((value) => value);
    let isValid = isCurrentServiceValid;
    if (showNextService) {
      const isNextServiceValid = Object.values(serviceHistoryData.nextService.serviceMetrics).some((value) => value) && serviceHistoryData.nextService.occurred !== null;
      isValid = isValid && isNextServiceValid;
    }
    setValid(isValid);
  }, [serviceHistoryData, metricsErrors, dateError, showNextService]);

  useEffect(() => {
    if (!open) return;

    const initialServiceMetrics = item ? Object.keys(item.serviceMetrics).reduce((acc, metricId) => {
      if (item.thingServiceMetrics.some((m) => m.serviceMetricId === metricId)) {
        acc[metricId] = item.serviceMetrics[metricId] as number;
      }
      return acc;
    }, {}) : {};

    setServiceHistoryData((prev) => ({
      ...prev,
      currentService: {
        serviceTypeId: item?.serviceTypeId || '',
        details: item?.details || '',
        occurred: item ? new Date(item.occurred) : new Date(),
        serviceMetrics: initialServiceMetrics,
      },
    }));

    setMetricsErrors({});
    setDateError(undefined);
    setShowNextService(isNextServiceEnabled || false);
  }, [open, item]);

  const actions = (
    <>
      {!isNewService && <ConfirmDialog
        sx={{ padding: 0 }}
        confirmPrompt={<Typography color="text.secondary" variant="body2">{t('common:page.thing-detail.service-history-dialog.confirm-delete.action')}</Typography>}
        initialPrompt={t('admin:page.application-detail.delete.action')}
        onConfirm={handleDelete}
        buttonColor="error"
      />}
      <Button
        data-testid="cancel-button"
        disabled={saveInProgress}
        onClick={handleClose}
        size="medium"
        variant="outlined"
      >
        {t('common:common.action.cancel')}
      </Button>
      <LoadingButton
        data-testid="save-button"
        disabled={!valid || saveInProgress}
        loadingCaption={t('common:common.hint.saving')}
        size="medium"
        task={handleSave}
        sx={{ minWidth: isNewService ? 110 : 80 }}
        variant="contained"
      >
        {isNewService ? t('common:page.thing-detail.service-history-dialog.add-service.action') : t('common:page.thing-detail.service-history-dialog.modify.action')}
      </LoadingButton>
    </>
  );

  return (
    <ServiceHistoryDialog
      data-testid={props['data-testid']}
      title={isNewService ? t('common:page.thing-detail.service-history-dialog.add-new-service.labels') : t('common:page.thing-detail.service-history.service-history-title.labels')}
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      open={open}
      actions={actions}
    >
      <Stack spacing={2}>
        <ServiceTypeSelect
          serviceTypes={serviceTypes}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setServiceHistoryData((prev) => ({
              ...prev,
              currentService: {
                ...prev.currentService,
                serviceTypeId: e.target.value,
              },
            }));
          }}
          value={serviceHistoryData.currentService.serviceTypeId}
        />
        <Stack sx={{ width: 1, flexDirection: smallScreen ? 'column' : 'row', alignItems: smallScreen ? 'center' : 'flex-start', justifyContent: 'space-between', flexWrap: 'nowrap', '& .MuiFormControl-root': { width: smallScreen ? 1 : 'auto' } }}>
          <DatePicker
            key="service-date-picker"
            data-testid="service-date-picker"
            label={t('common:page.thing-detail.service-history-dialog.date-of-service.labels')}
            size="small"
            date={isUndefined(serviceHistoryData.currentService.occurred) ? null : DateTime.fromISO(String(DateTime.fromJSDate(serviceHistoryData.currentService.occurred)), { zone: DEFAULT_TIME_ZONE })}
            disableFuture
            onChange={handleDateChange}
            placeholder={isUndefined(serviceHistoryData.currentService.occurred) ? t('common:component.properties.hint.not-set') : ''}
            PopperProps={{ disablePortal: true }}
            DialogProps={{ disablePortal: true }}
            required
            helperText={dateError && <Typography variant="caption" sx={{ color: 'red' }}>{t(`common:component.date-picker.hint.${dateError}`)}</Typography>}
            dateError={dateError}
            desktopModeMediaQuery='@media (min-width: 0px)'
          />
          {
            isPreFillEnabled &&
            <Tooltip title={<Typography variant="body2" sx={{ maxWidth: 150 }}>{t('common:page.thing-detail.service-history-dialog.pre-fill-readings.hint.tooltip')}</Typography>} arrow>
              <Box sx={{ whiteSpace: smallScreen ? 'normal' : 'nowrap', mt: smallScreen ? 2 : '2px', ml: smallScreen ? 0 : 2, minWidth: 154 }}>
                <LoadingButton
                  data-testid="pre-fill-metrics-button"
                  disabled={saveInProgress || !!dateError}
                  loadingCaption={t('common:common.labels.loading')}
                  size="medium"
                  variant="text"
                  task={handlePreFill}
                >
                  {t('common:page.thing-detail.service-history-dialog.pre-fill-readings.action')}
                </LoadingButton>
              </Box>
            </Tooltip>
          }
        </Stack>
        <ServiceMetricFields
          onChange={handleMetricChange}
          serviceMetricValues={serviceHistoryData.currentService.serviceMetrics}
          serviceMetrics={serviceMetrics}
          setMetricsErrors={setMetricsErrors}
        />
        <TextField
          label={t('common:page.thing-detail.service-history-dialog.enter-service-details.labels')}
          multiline
          rows={5}
          value={serviceHistoryData.currentService.details}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setServiceHistoryData((prev) => ({
              ...prev,
              currentService: {
                ...prev.currentService,
                details: value,
              },
            }));
          }}
          data-testid="service-details-input"
          helperText={`${serviceHistoryData.currentService.details?.length || 0}/${CHARACTER_LIMIT}`}
          inputProps={{ maxLength: CHARACTER_LIMIT }}
        />
      </Stack>
      {
        isNextServiceEnabled &&
        <Stack spacing={2}>
          <FormGroup data-testid="next-service">
            <FormControlLabel
              control={<Switch checked={showNextService} size="small" onChange={(e: ChangeEvent<HTMLInputElement>) => { setShowNextService(e.target.checked); }} data-testid="next-service-switch" />}
              label={<Typography variant="subtitle1" sx={{ ml: 1 }}>{t('common:page.thing-detail.service-history-dialog.next-service-switch.labels')}</Typography>}
            />
          </FormGroup>
          <Collapse in={showNextService} sx={{ width: 1 }}>
            <NextServiceHistory
              item={item}
              serviceMetrics={serviceMetrics}
              serviceTypes={serviceTypes}
              setValid={setValid}
              setServiceHistoryData={setServiceHistoryData}
              nextServiceData={serviceHistoryData.nextService}
              isNextServiceOpen={showNextService}
            />
          </Collapse>
        </Stack>
      }
    </ServiceHistoryDialog>
  );
};
